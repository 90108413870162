import { render, staticRenderFns } from "./Quadro.vue?vue&type=template&id=42b4e407&scoped=true"
import script from "./Quadro.vue?vue&type=script&lang=js"
export * from "./Quadro.vue?vue&type=script&lang=js"
import style0 from "./Quadro.vue?vue&type=style&index=0&id=42b4e407&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b4e407",
  null
  
)

export default component.exports