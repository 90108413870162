var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stage',{ref:"stage",staticStyle:{"margin":"10px"},attrs:{"config":_vm.stageSize}},[_c('v-layer',[_c('v-line',{attrs:{"listening":false,"config":{
        points: [_vm.paddingX, _vm.padding, _vm.paddingX, _vm.stageSize.height - _vm.padding],
        stroke: 'black',
        strokeWidth: 1,
        lineCap: 'round',
        lineJoin: 'round',
      }}}),_c('v-circle',{attrs:{"listening":false,"config":{
        x: _vm.paddingX,
        y: _vm.padding,
        radius: 5,
        fill: 'black',
      }}}),_c('v-circle',{attrs:{"listening":false,"config":{
        x: _vm.paddingX,
        y: _vm.padding + (_vm.stageSize.height - _vm.padding * 2) / 3,
        radius: 5,
        fill: 'black',
      }}}),_c('v-circle',{attrs:{"listening":false,"config":{
        x: _vm.paddingX,
        y: _vm.stageSize.height - (_vm.stageSize.height - _vm.padding * 2) / 3 - _vm.padding,
        radius: 5,
        fill: 'black',
      }}}),_c('v-circle',{attrs:{"listening":false,"config":{
        x: _vm.paddingX,
        y: _vm.stageSize.height - _vm.padding,
        radius: 5,
        fill: 'black',
      }}}),_c('v-text',{attrs:{"listening":false,"config":{
        text: _vm.title,
        fontSize: 20,
        fill: 'gray',
        x: 0,
        y: 0,
      }}}),_c('v-text',{attrs:{"listening":false,"config":{
        text: _vm.isWoman ? _vm.labelsWomen[0] : _vm.labelsMen[0],
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',
        width: 140,
        x: _vm.paddingX + 20,
        y: _vm.padding - 7,
      }}}),_c('v-text',{attrs:{"listening":false,"config":{
        text: _vm.isWoman ? _vm.labelsWomen[1] : _vm.labelsMen[1],
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',
        width: 140,
        x: _vm.paddingX + 20,
        y: _vm.padding + (_vm.stageSize.height - _vm.padding * 2) / 3 - 7,
      }}}),_c('v-text',{attrs:{"listening":false,"config":{
        text: _vm.isWoman ? _vm.labelsWomen[2] : _vm.labelsMen[2],
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',
        width: 140,
        x: _vm.paddingX + 20,
        y:
          _vm.stageSize.height -
          (_vm.stageSize.height - _vm.padding * 2) / 3 -
          _vm.padding -
          7,
      }}}),_c('v-text',{attrs:{"listening":false,"config":{
        text: _vm.isWoman ? _vm.labelsWomen[3] : _vm.labelsMen[3],
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',
        width: 140,
        x: _vm.paddingX + 20,
        y: _vm.stageSize.height - _vm.padding - 7,
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }