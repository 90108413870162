<template>
  <div v-if="resultedData">
    <div class="quadro-test no-selection">
      <a-divider class="dividerTitle">
        {{ $t("quadro-test.TestResultsLiked") }}
      </a-divider>

      <div class="quadro-results">
        <a-list
          item-layout="horizontal"
          :data-source="resultedData.images.slice(0, 8)"
        >
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta description="">
              <a slot="title">{{ 1 + index + ". " + item.key }}</a>
              <a-popover slot="avatar" :title="item.key">
                <template slot="content">
                  <img :src="$strapi.storageUrl + item.url" />
                </template>
                <img
                  :id="item.url"
                  :src="$strapi.storageUrl + item.url"
                  style="width: 30px"
                />
              </a-popover>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>

      <a-divider class="dividerTitle">
        {{ $t("quadro-test.TestResultsDisliked") }}
      </a-divider>

      <div class="quadro-results">
        <a-list
          item-layout="horizontal"
          :data-source="resultedData.images.slice(8, 16)"
        >
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta description="">
              <a slot="title">{{ 1 + index + ". " + item.key }}</a>
              <a-popover slot="avatar" :title="item.key">
                <template slot="content">
                  <img :src="$strapi.storageUrl + item.url" />
                </template>
                <img
                  :id="item.url"
                  :src="$strapi.storageUrl + item.url"
                  style="width: 30px"
                />
              </a-popover>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>

      <a-divider class="dividerTitle">
        {{ $t("quadro-test.TestResultsPerson") }}
      </a-divider>

      <div class="quadro-results" style="margin-bottom: 60px">
        <a-list item-layout="horizontal" :data-source="resultedData.person">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta description="">
              <a slot="title">{{ 1 + index + ". " + item.key }}</a>
              <a-popover slot="avatar" :title="item.key">
                <template slot="content">
                  <img :src="$strapi.storageUrl + item.url" />
                </template>
                <img
                  :id="item.url"
                  :src="$strapi.storageUrl + item.url"
                  style="width: 30px"
                />
              </a-popover>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>

      <a-divider class="dividerTitle">
        {{ "" }}
        <!--  -->
      </a-divider>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  props: {
    result: {
      default: null,
      type: Object,
    },
    resultedData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      images: [],
    };
  },
  computed: {
    id() {
      return this.id;
    },
    profile() {
      return this.profile;
    },
    ...mapGetters(["id", "profile", "isInterpretator", "isAdmin"]),
  },
  async mounted() {
    if (!this.$isServer) {
      // this.id = params
      if (!this.$isServer) {
        await this.$store.dispatch("authCustom/authAction");
      }
      // console.log(this.result.user)
      // console.log(this.$store.state.authCustom.auth)
      // console.log(this.id)
      // console.log(this.result)
      let disabled =
        !this.$store.state.authCustom.auth ||
        !this.result.user ||
        this.id !== this.result.user;
      if (this.isInterpretator || this.isAdmin) {
        disabled = false;
      }
      const data = {
        textId: "buttons.downloadResult",
        visible: true,
        disabled,
      };
      const callback = this.downloadResult;
      this.$store.commit("ui/setActionButton", {
        data,
        callback,
      });

      for (let i = 0; i < 16; i++) {
        const img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.src = this.$strapi.storageUrl + this.resultedData.images[i].url;
        this.images.push(img);
      }
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = this.$strapi.storageUrl + this.resultedData.person[0].url;
      this.images.push(img);
    }
  },
  methods: {
    ...mapMutations({ setActionButton: "ui/setActionButton" }),
    async downloadResult() {
      if (!this.$isServer) {
        // this.$nextTick(() => {
        //   this.$loading.start();
        //   // setTimeout(() => this.$loading.finish(), 500)
        // });
        // const frontCanvas = document
        //   .querySelector('#chart1')
        //   .querySelector('#bar-chart')
        // const backCanvas = document
        //   .querySelector('#chart2')
        //   .querySelector('#bar-chart')

        // // create image from dummy canvas
        // const frontCanvasImg = frontCanvas.toDataURL('image/png', 1.0)
        // const backCanvasImg = backCanvas.toDataURL('image/png', 1.0)

        const today = new Date(this.result.dateend);
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        const todayStr =
          dd.toString() +
          "/" +
          mm.toString() +
          "/" +
          yyyy.toString() +
          " " +
          today.toLocaleTimeString().slice(0, -3);
        const caption1 = this.$t("test.testResultsDate") + " " + todayStr;

        const fileName =
          this.$t("quadro-test.testResultsPrefix") + todayStr + ".pdf";

        const pdfMake = require("pdfmake");

        const pdfFonts = require("pdfmake/build/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        let profile = this.profile;
        if (this.isInterpretator || this.isAdmin) {
          profile = await this.$store.dispatch(
            "users/fetchUser",
            this.result.user
          );
        }
        const caption2 = profile.firstname + " " + profile.secondname;

        const images = {};
        // eslint-disable-next-line prefer-const
        let columnsLiked = [];
        // eslint-disable-next-line prefer-const
        let columnsDisliked = [];
        // eslint-disable-next-line prefer-const
        let columnsPerson = [];
        for (let i = 0; i < 16; i++) {
          const c = document.createElement("canvas");
          c.setAttribute("crossOrigin", "anonymous");
          c.height = 28; // img.naturalHeight
          c.width = 20; // img.naturalWidth
          const ctx = c.getContext("2d");
          ctx.drawImage(this.images[i], 0, 0, c.width, c.height);
          const base64String = c.toDataURL("image/png", 1.0);
          images[this.resultedData.images[i].url] = base64String;

          const temp = {
            alignment: "left", // i < 8 ? 'left' : 'right',
            text:
              ((i % 8) + 1).toString() + " " + this.resultedData.images[i].key, // this.$t('quadro-test.TestResultsLiked'),
            width: 1191 / 3,
            color: "black",
            fontSize: 10,
            absolutePosition: {
              // absolute positioning if required
              x: i < 8 ? 100 : 1191 / 2 - 100, // 50
              y: 50 + 5 + 35 + (i % 8) * 35 + 40,
            },
          };
          const tempImg = {
            image: this.resultedData.images[i].url,
            width: 20, // for 50 % image width
            height: 28, // change the numbers accordingly
            absolutePosition: {
              // absolute positioning if required
              x: (i < 8 ? 100 : 1191 / 2 - 100) - 40,
              y: 50 + 5 + 25 + (i % 8) * 35 + 40,
            },
          };
          if (i < 8) {
            columnsLiked.push(temp);
            columnsLiked.push(tempImg);
          } else {
            columnsDisliked.push(temp);
            columnsDisliked.push(tempImg);
          }
        }

        const c = document.createElement("canvas");
        c.setAttribute("crossOrigin", "anonymous");
        c.height = 28; // img.naturalHeight
        c.width = 20; // img.naturalWidth
        const ctx = c.getContext("2d");
        ctx.drawImage(this.images[16], 0, 0, c.width, c.height);
        const base64String = c.toDataURL("image/png", 1.0);
        images[this.resultedData.person[0].url] = base64String;

        const person = {
          alignment: "left", // i < 8 ? 'left' : 'right',
          text: this.resultedData.person[0].key, // (i % 8 + 1).toString() + ' ' + this.resultedData.images[i].key, // this.$t('quadro-test.TestResultsLiked'),
          width: 1191 / 3,
          color: "black",
          fontSize: 10,
          absolutePosition: {
            // absolute positioning if required
            x: 100,
            y: 50 + 5 + 35 + 9 * 30 + 70 + 40,
          },
        };

        const personImage = {
          image: this.resultedData.person[0].url,
          width: 20, // for 50 % image width
          height: 28, // change the numbers accordingly
          absolutePosition: {
            // absolute positioning if required
            x: 100 - 40,
            y: 50 + 5 + 35 + 9 * 30 + 60 + 40,
          },
        };

        columnsPerson.push(person);
        columnsPerson.push(personImage);

        const docDefinition = {
          info: {
            title: this.$t("meta.title.default"),
            author: "https://psyhotest.com",
            subject: this.$t("quadro-test.TestResultsSubjectProfTesting"),
            keywords: this.$t("meta.title.default"),
          },
          pageSize: "A4", // 1191 pixels x 842 pixels
          // 420 pixels x 595 pixels
          pageMargins: [10, 10, 10, 0],
          pageOrientation: "landscape",
          content: [
            {
              alignment: "left",
              text: caption1,
              width: 580,
              color: "gray",
              fontSize: 10,
            },
            {
              alignment: "left",
              text: caption2,
              width: 580,
              color: "black",
              fontSize: 12,
            },
            {
              alignment: "left",
              text: this.$t("quadro-test.TestResultsLiked"),
              width: 1191 / 2,
              color: "black",
              fontSize: 12,
              absolutePosition: {
                // absolute positioning if required
                x: 70,
                y: 50 + 40,
              },
            },
            {
              alignment: "justify",
              columns: columnsLiked,
            },
            {
              alignment: "left",
              text: this.$t("quadro-test.TestResultsDisliked"),
              width: 1191 / 2,
              color: "black",
              fontSize: 12,
              absolutePosition: {
                // absolute positioning if required
                x: 1191 / 2 - 100,
                y: 50 + 40,
              },
            },
            {
              alignment: "justify",
              columns: columnsDisliked,
            },
            {
              alignment: "left",
              text: this.$t("quadro-test.TestResultsPerson"),
              width: 1191 / 2,
              color: "black",
              fontSize: 12,
              absolutePosition: {
                // absolute positioning if required
                x: 70,
                y: 390 + 40,
              },
            },
            {
              // alignment: 'center',
              columns: columnsPerson,
            },
            {
              alignment: "right",
              text: this.$t("quadro-test.TestResultsAdv"),
              link: "https://psyhotest.com/results/" + this.result.udid,
              width: 580,
              absolutePosition: {
                // absolute positioning if required
                x: 20,
                y: 570,
              },
              color: "gray",
              fontSize: 10,
            },
          ],
          images,
          compress: false,
          styles: {
            header: {
              fontSize: 15,
              bold: true,
            },
            body: {},
            anotherStyle: {
              italics: true,
              alignment: "right",
            },
          },
          defaultStyle: {
            fontSize: 12,
            bold: false,
            // fillColor: '#bada55'
          },
        };

        pdfMake.createPdf(docDefinition).download(fileName);

        // this.$nextTick(() => {
        //   // this.$loading.start()
        //   setTimeout(() => this.$loading.finish(), 500);
        // });
        return null;
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.quadro-results {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.figure {
  justify-self: center;
  // width: 200px;
  // height: auto;
}

.figure-enter-active,
.figure-leave-active {
  transition: opacity 1.5s;
}
.figure-enter,
.figure-leave-to {
  opacity: 0;
}

.figure-out-in-enter-active {
  transition: opacity 1s;
}

.figure-out-in-leave-active {
  transition: opacity 0s;
  opacity: 0;
}

.figure-out-in-enter-active {
  transition-delay: 0.7s;
}

.figure-out-in-enter,
.figure-out-in-leave-to {
  opacity: 0;
}

/* Transitions using the page hook */
.page-enter-active,
.page-leave-active {
  transition: all 0.3s ease-out;
}
.page-enter,
.page-leave-active {
  opacity: 0;
  transform-origin: 50% 50%;
}

#grid {
  display: grid;
  grid-template-columns: 30% 30% 30%; // repeat(4, 0.8fr);
  justify-content: center;
  justify-items: center;
  grid-gap: 10px;
  margin-left: 1%;
  margin-right: 1%;
}
.grid-text {
  height: 80px;
}

#grid div {
  display: grid;
  /* justify-items: center; */
  justify-self: center;
}

.grid img {
  position: relative;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  max-width: 90%;
}

/*    Padding Ul   */
.quadro-test ul {
  padding: 0px;
}

.quadro-test {
  overflow: hidden;
}

@media only screen and (max-width: 700px) {
  .figure {
    display: none;
  }

  #grid {
    grid-template-columns: 5% 80% 5%;
  }

  .grid-text {
    height: 50px;
  }
}
</style>
