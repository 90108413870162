<template>
  <a-popover
    v-if="data && data.length === 4"
    :title="currentTitle"
    :visible="popoverVisible && $store.state.ui.circlePopoverIndex === id"
    placement="left"
  >
    <template slot="content">
      <img style="width: 200px" :src="popoverUrl" />
    </template>
    <v-stage ref="stage" :config="stageSize" style="margin: 0px">
      <v-layer>
        <v-text
          :listening="false"
          :config="{
            text: title,
            fontSize: 15,
            fill: 'gray',
            x: padding - 30,
            y: padding - 30,
          }"
        />
        <!--  -->
        <v-text
          :listening="false"
          :config="{
            text: values ? 'Ⅰ (+)' : 'Ⅰ',
            fontSize: 20,
            fill: 'rgba(0,0,0,0.8)',
            width: 100,
            align: 'center',
            x: stageSize.width / 2 - 50,
            y: padding - 30,
          }"
        />
        <v-text
          :listening="false"
          :config="{
            text: values ? '(–) Ⅱ' : 'Ⅱ',
            fontSize: 20,
            fill: 'rgba(0,0,0,0.8)',
            x: values ? padding - 60 : padding - 25,
            y: stageSize.height / 2 - 10,
          }"
        />
        <v-text
          :listening="false"
          :config="{
            text: values ? 'Ⅲ (0)' : 'Ⅲ',
            fontSize: 20,
            fill: 'rgba(0,0,0,0.8)',
            x: stageSize.width - padding + 10,
            y: stageSize.height / 2 - 10,
          }"
        />
        <v-text
          :listening="false"
          :config="{
            text: values ? 'Ⅳ (±)' : 'Ⅳ',
            fontSize: 20,
            width: 100,
            align: 'center',
            fill: 'rgba(0,0,0,0.8)',
            x: stageSize.width / 2 - 50,
            y: stageSize.height - padding + 10,
          }"
        />
        <!-- vectors -->
        <!-- v-if values -->
        <v-text
          id="blur"
          :listening="false"
          v-if="values"
          :config="{
            text: vectors[0] === '' ? '–' : vectors[0],
            fontSize: 15,
            fill: vectors[0] === '' ? 'red' : '#0c93a4',
            width: 100,
            align: 'center',
            x: stageSize.width / 2 - 50,
            y: padding - 50,
          }"
        />
        <!--  -->
        <v-text
          id="blur"
          :listening="false"
          v-if="values"
          :config="{
            text: vectors[1] === '' ? '–' : vectors[1],
            fontSize: 15,
            fill: vectors[1] === '' ? 'red' : '#0c93a4',
            width: 60,
            align: 'center',
            x: padding - 60,
            y: stageSize.height / 2 + 15,
          }"
        />
        <v-text
          id="blur"
          :listening="false"
          v-if="values"
          :config="{
            text: vectors[2] === '' ? '–' : vectors[2],
            fontSize: 15,
            width: 60,
            align: 'center',
            fill: vectors[2] === '' ? 'red' : '#0c93a4',
            x: stageSize.width / 2 + padding + 10,
            y: stageSize.height / 2 + 15,
          }"
        />
        <v-text
          id="blur"
          :listening="false"
          v-if="values"
          :config="{
            text: vectors[3] === '' ? '–' : vectors[3],
            fontSize: 15,
            width: 100,
            align: 'center',
            fill: vectors[3] === '' ? 'red' : '#0c93a4',
            x: stageSize.width / 2 - 50,
            y: stageSize.height - padding + 35,
          }"
        />
        <!-- v-if not values -->
        <v-text
          v-if="!values"
          ref="0"
          :config="{
            text: $t('test-szondi.CircleOneMeansControl'),
            fontSize: 15,
            fill: '#0c93a4',
            width: 150,
            align: 'center',
            x: stageSize.width / 2 - 75,
            y: padding - 50,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          v-if="!values"
          ref="1"
          :config="{
            text: $t('test-szondi.CircleTwoMeansControl'),
            fontSize: 15,
            width: 70,
            align: 'center',
            fill: '#0c93a4',
            x: padding - 75,
            y: stageSize.height / 2 + 15,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          v-if="!values"
          ref="2"
          :config="{
            text: $t('test-szondi.CircleThreeMeansControl'),
            fontSize: 15,
            fill: '#0c93a4',
            width: 70,
            align: 'center',
            x: stageSize.width - padding - 5,
            y: stageSize.height / 2 + 15,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          v-if="!values"
          ref="3"
          :config="{
            text: $t('test-szondi.CircleFourMeansControl'),
            fontSize: 15,
            fill: '#0c93a4',
            width: 150,
            align: 'center',
            x: stageSize.width / 2 - 75,
            y: stageSize.height - padding + 35,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <!-- !!!!!! -->
        <v-text
          v-if="!values"
          ref="0"
          :config="{
            text: data[0].value,
            fontSize: 10,
            fill: 'black',
            width: 170,
            align: 'center',
            x: stageSize.width / 2 + 70,
            y: padding - 80,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          v-if="!values"
          ref="1"
          :config="{
            text: data[1].value,
            fontSize: 10,
            width: 170,
            align: 'center',
            fill: 'black',
            x: padding - 150,
            y: stageSize.height / 2 - 100,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          v-if="!values"
          ref="2"
          :config="{
            text: data[2].value,
            fontSize: 10,
            fill: 'black',
            width: 150,
            align: 'center',
            x: stageSize.width - padding - 5,
            y: stageSize.height / 2 + 65,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          v-if="!values"
          ref="3"
          :config="{
            text: data[3].value,
            fontSize: 10,
            fill: 'black',
            width: 150,
            align: 'center',
            x: stageSize.width / 2 - 210,
            y: stageSize.height - padding + 0,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />

        <!--  -->
        <v-shape
          :listening="false"
          :config="{
            sceneFunc: function (context, shape) {
              let dimention =
                stageSize.width < stageSize.height
                  ? stageSize.width
                  : stageSize.height;
              dimention -= padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(dimention / 2 + padding, padding);
              context.lineTo(dimention / 2 + padding, dimention + padding);
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: direction ? '#0c93a4' : 'black',
            strokeWidth: direction ? 3 : 1,
          }"
        />
        <v-shape
          :listening="false"
          :config="{
            sceneFunc: function (context, shape) {
              let dimention =
                stageSize.width < stageSize.height
                  ? stageSize.width
                  : stageSize.height;
              dimention -= padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(padding, dimention / 2 + padding);
              context.lineTo(dimention + padding, dimention / 2 + padding);
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: direction ? '#0c93a4' : 'black',
            strokeWidth: direction ? 3 : 1,
          }"
        />
        <v-shape
          :listening="false"
          :config="{
            sceneFunc: function (context, shape) {
              let dimention =
                stageSize.width < stageSize.height
                  ? stageSize.width
                  : stageSize.height;
              dimention -= padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(padding, dimention / 2 + padding);
              context.arcTo(
                padding,
                padding,
                dimention / 2 + padding,
                padding,
                radius
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: direction ? '#0c93a4' : 'black',
            strokeWidth: direction ? 3 : 1,
          }"
        />
        <v-shape
          :listening="false"
          :config="{
            sceneFunc: function (context, shape) {
              let dimention =
                stageSize.width < stageSize.height
                  ? stageSize.width
                  : stageSize.height;
              dimention -= padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(dimention / 2 + padding, padding);
              context.arcTo(
                dimention + padding,
                padding,
                dimention + padding,
                dimention / 2 + padding,
                radius
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 1,
          }"
        />
        <v-shape
          :listening="false"
          :config="{
            sceneFunc: function (context, shape) {
              let dimention =
                stageSize.width < stageSize.height
                  ? stageSize.width
                  : stageSize.height;
              dimention -= padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(dimention / 2 + padding, dimention + padding);
              context.arcTo(
                dimention + padding,
                dimention + padding,
                dimention + padding,
                dimention / 2 + padding,
                radius
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 1,
          }"
        />
        <v-shape
          :listening="false"
          :config="{
            sceneFunc: function (context, shape) {
              let dimention =
                stageSize.width < stageSize.height
                  ? stageSize.width
                  : stageSize.height;
              dimention -= padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(dimention / 2 + padding, dimention + padding);
              context.arcTo(
                padding,
                dimention + padding,
                padding,
                dimention / 2 + padding,
                radius
              );

              // special Konva.js method
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 1,
          }"
        />
        <!--  -->
        <v-arrow
          :listening="false"
          :config="{
            x: stageSize.width / 2,
            y: stageSize.height - padding,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
          }"
        />
        <v-arrow
          :listening="false"
          :config="{
            x: stageSize.width / 2,
            y: padding,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
            points: [0, 0, -1, 0],
          }"
        />
        <v-arrow
          :listening="false"
          :config="{
            x: padding,
            y: stageSize.height / 2,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
            points: [0, 0, 0, 1],
          }"
        />
        <v-arrow
          :listening="false"
          :config="{
            x: stageSize.width - padding,
            y: stageSize.height / 2,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
            points: [0, 0, 0, -1],
          }"
        />
        <!--  -->
        <v-arrow
          :listening="false"
          v-if="direction"
          :config="{
            x: stageSize.width / 2 - (padding / 4) * 2,
            y: stageSize.height / 2,
            radius: 1,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
          }"
        />
        <v-arrow
          :listening="false"
          v-if="direction"
          :config="{
            x: stageSize.width / 2 + (padding / 4) * 2,
            y: stageSize.height / 2,
            radius: 1,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
          }"
        />

        <v-arrow
          :listening="false"
          v-if="direction"
          :config="{
            x:
              (stageSize.width / 2 - padding) * Math.cos(3.9) +
              stageSize.width / 2,
            y:
              (stageSize.width / 2 - padding) * Math.sin(3.9) +
              stageSize.height / 2,
            radius: 2,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
            points: [0, 0, -0.5, 0.5],
          }"
        />
        <v-arrow
          :listening="false"
          v-if="direction"
          :config="{
            x: stageSize.width / 2,
            y: stageSize.height / 2 + (padding / 4) * 2,
            radius: 2,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
            points: [0, 0, 0, -1],
          }"
        />
        <v-arrow
          :listening="false"
          v-if="direction"
          :config="{
            x: stageSize.width / 2,
            y: stageSize.height / 2 - (padding / 4) * 2,
            radius: 2,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
            points: [0, 0, 0, -1],
          }"
        />
        <!--  -->
        <v-circle
          :listening="false"
          :config="{
            x: stageSize.width / 2,
            y: stageSize.height / 2,
            radius: 5,
            fill: 'black',
          }"
        />
        <!--  -->
      </v-layer>
    </v-stage>
  </a-popover>
</template>

<script>
export default {
  props: {
    isAvailable: {
      default: false,
      type: Boolean,
    },
    padding: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    id: {
      type: Number,
      default: 0,
    },
    values: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    vectors: {
      type: Array,
      default: () => ["h, s", "e, hy", "k, p, d", "m"],
    },
    data: {
      type: Array,
      default: () => [
        { value: "Безсилие", image: "" },
        { value: "Требование того, что у него не должно быть", image: "" },
        {
          value:
            "Соединение 2ух семей (тайной и явной) - скрывает, что ходит в секту",
          image: "",
        },
        { value: "Гипертонический заклинатель будущего", image: "" },
      ],
    },
  },
  data() {
    return {
      filters: [Konva.Filters.Pixelate],
      currentTarget: null,
      popoverUrl: null,
      popoverVisible: false,
      currentTitle: "",
    };
  },
  computed: {
    stageSize() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
  mounted() {
    this.cacheCall();
  },
  updated() {
    // recache
    this.cacheCall();
  },
  methods: {
    cacheCall() {
      const stage = this.$refs.stage.getStage();
      for (let i = 0; i < stage.children.length; i++) {
        for (let j = 0; j < stage.children[i].children.length; j++) {
          if (
            stage.children[i].children[j].VueComponent.$vnode.data.attrs.id ===
            "blur"
          ) {
            let node = stage.children[i].children[j];
            node.cache();
            if (!this.isAvailable) {
              node.filters([Konva.Filters.Pixelate]);
              node.pixelSize(6);
            } else {
              node.pixelSize(1);
            }
          }
        }
      }
    },
    handleTap(event) {
      if (this.popoverVisible) {
        this.handleMouseOut(event);
      } else {
        this.handleMouseMove(event);
      }
    },
    handleMouseOut() {
      this.currentTarget = null;
      this.popoverVisible = false;
    },
    handleMouseMove(event) {
      this.popoverVisible = true;
      if (event && event.currentTarget && event.currentTarget._partialText) {
        if (this.currentTarget !== event.currentTarget._partialText) {
          const index = this.vectors.indexOf(event.currentTarget._partialText);
          this.currentTarget = event.currentTarget._partialText;
          if (index >= 0) {
            this.popoverUrl = this.data[index].image;
          }
          this.$store.commit("ui/setCirclePopoverIndex", this.id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
