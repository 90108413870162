import { render, staticRenderFns } from "./MalcevCircle.vue?vue&type=template&id=386c31d0&scoped=true"
import script from "./MalcevCircle.vue?vue&type=script&lang=js"
export * from "./MalcevCircle.vue?vue&type=script&lang=js"
import style0 from "./MalcevCircle.vue?vue&type=style&index=0&id=386c31d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386c31d0",
  null
  
)

export default component.exports