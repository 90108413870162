<template>
  <v-stage ref="stage" :config="stageSize" style="margin: 10px">
    <v-layer>
      <v-line
        :listening="false"
        :config="{
          points: [paddingX, padding, paddingX, stageSize.height - padding],
          stroke: 'black',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <!--  -->
      <v-circle
        :listening="false"
        :config="{
          x: paddingX,
          y: padding,
          radius: 5,
          fill: 'black',
        }"
      />
      <v-circle
        :listening="false"
        :config="{
          x: paddingX,
          y: padding + (stageSize.height - padding * 2) / 3,
          radius: 5,
          fill: 'black',
        }"
      />
      <v-circle
        :listening="false"
        :config="{
          x: paddingX,
          y: stageSize.height - (stageSize.height - padding * 2) / 3 - padding,
          radius: 5,
          fill: 'black',
        }"
      />
      <v-circle
        :listening="false"
        :config="{
          x: paddingX,
          y: stageSize.height - padding,
          radius: 5,
          fill: 'black',
        }"
      />
      <!--  -->
      <v-text
        :listening="false"
        :config="{
          text: title,
          fontSize: 20,
          fill: 'gray',
          x: 0,
          y: 0,
        }"
      />
      <!--  -->
      <v-text
        :listening="false"
        :config="{
          text: isWoman ? labelsWomen[0] : labelsMen[0],
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',
          width: 140,
          x: paddingX + 20,
          y: padding - 7,
        }"
      />
      <v-text
        :listening="false"
        :config="{
          text: isWoman ? labelsWomen[1] : labelsMen[1],
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',
          width: 140,
          x: paddingX + 20,
          y: padding + (stageSize.height - padding * 2) / 3 - 7,
        }"
      />
      <v-text
        :listening="false"
        :config="{
          text: isWoman ? labelsWomen[2] : labelsMen[2],
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',
          width: 140,
          x: paddingX + 20,
          y:
            stageSize.height -
            (stageSize.height - padding * 2) / 3 -
            padding -
            7,
        }"
      />
      <v-text
        :listening="false"
        :config="{
          text: isWoman ? labelsWomen[3] : labelsMen[3],
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',
          width: 140,
          x: paddingX + 20,
          y: stageSize.height - padding - 7,
        }"
      />
    </v-layer>
  </v-stage>
</template>

<script>
const width = 200; // window.innerWidth
const height = 900; // window.innerHeight

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    labelsMen: {
      type: Array,
      default: () => [
        "Профессиональная составляющая",
        "Профессиональная и собирательная составляющая",
        "Духовная составляющая",
        "Духовная и индивидуальная составляющая",
      ],
    },
    labelsWomen: {
      type: Array,
      default: () => [
        "Духовная составляющая",
        "Духовная и интеллектуальная составляющая",
        "Профессиональная составляющая",
        "Профессиональная и индивидуальная составляющая",
      ],
    },
    isWoman: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stageSize: {
        width,
        height,
      },
      padding: 100,
      paddingX: 10,
    };
  },
};
</script>

<style lang="scss" scoped>
//
</style>
