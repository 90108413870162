<template>
  <div>
    <component
      :is="result && result.testtype"
      :result="result"
      :resulted-data="resultedData"
    />
  </div>
</template>

<script>
import Szondi from "./components/Szondi.vue";
import Quadro from "./components/Quadro.vue";
export default {
  //   middleware: 'notAuthenticated',
  components: {
    Szondi,
    Quadro,
  },
  // async asyncData(route, context) {

  // },
  data() {
    return {
      result: null,
      resultedData: null,
    };
  },
  methods: {
    async waitFor(ms) {
      return new Promise(function (resolve) {
        setTimeout(resolve, ms || 0);
      });
    },
    // async removeReplain() {
    //   const show = false;

    //   if (!process.server) {
    //     const x = document.getElementById("__replain_widget_iframe");
    //     if (x) {
    //       x.style.display = show ? "unset" : "none";
    //     }
    //   }

    //   await this.waitFor(2000);
    //   if (!process.server) {
    //     const x = document.getElementById("__replain_widget_iframe");
    //     if (x) {
    //       x.style.display = show ? "unset" : "none";
    //     }
    //   }

    //   await this.waitFor(5000);
    //   if (!process.server) {
    //     const x = document.getElementById("__replain_widget_iframe");
    //     if (x) {
    //       x.style.display = show ? "unset" : "none";
    //     }
    //   }
    // }
  },
  mounted() {
    // this.removeReplain();
  },
  async created() {
    await this.$store.dispatch("authCustom/fetchInterpretations");
    // console.log(this.$route);
    const params = this.$route.params;
    //console.log(params.udid);
    if (params.udid) {
      // console.log(context.params)
      try {
        const result = await this.$store.dispatch(
          "testResults/fetchTestresult",
          params.udid
        );

        //console.log(result);

        if (!result) {
          this.$router.push("/404");
          return;
        }

        this.result = result;
        this.resultedData = result.result;
      } catch (err) {
        this.$router.push("/404");
        return;
      }
    } else {
      this.$router.push("/");
    }
    // console.log(this.result);
    // return { result, resultedData: result.result };
    // this.$nextTick(() => {
    //   this.$loading.start();
    //   setTimeout(() => this.$loading.finish(), 500);
    // });
  },
};
</script>

<style scoped></style>
