<template>
  <section
    v-if="(data && data.length === 4) || (vectors && vectors.length === 4)"
  >
    <a-popover
      :title="currentTarget"
      :visible="popoverVisible && $store.state.ui.circlePopoverIndex === id"
      :placement="!$device.mobile ? placements[tempIndex] : 'top'"
      :auto-adjust-overflow="false"
      :arrow-point-at-center="true"
      :align="{ offset: !$device.mobile ? offsets[tempIndex] : [0, 110] }"
    >
      <!-- :align="{offset: ['50%','50%']}" -->

      <template slot="content">
        <img style="width: 200px" :src="popoverUrl" />
      </template>
      <!--  -->
      <!-- <span class="invisiblepopover"> -->
      <!--  -->
      <!-- </span> -->

      <v-stage ref="stage" :config="stageSize" style="margin: 0px">
        <v-layer>
          <v-text
            :listening="false"
            :config="{
              text: title,
              fontSize: 12,
              fill: 'gray',
              x: stageSize.width / 2 - 100,
              y: stageSize.height / 2 - 100,
            }"
          />
          <!--  -->
          <v-text
            :listening="false"
            :config="{
              text: values ? 'Ⅰ (+)' : 'Ⅰ',
              fontSize: 20,
              fill: 'rgba(0,0,0,0.8)',
              width: 100,
              align: 'center',
              x: stageSize.width / 2 - 50,
              y: padding - 30,
            }"
          />
          <v-text
            :listening="false"
            :config="{
              text: values ? '(–) Ⅱ' : 'Ⅱ',
              fontSize: 20,
              fill: 'rgba(0,0,0,0.8)',
              x: values ? padding - 60 : padding - 25 + 100,
              y: stageSize.height / 2 - 10,
            }"
          />
          <v-text
            :listening="false"
            :config="{
              text: values ? 'Ⅲ (0)' : 'Ⅲ',
              fontSize: 20,
              fill: 'rgba(0,0,0,0.8)',
              x: stageSize.width - padding + 10 - 100,
              y: stageSize.height / 2 - 10,
            }"
          />
          <v-text
            :listening="false"
            :config="{
              text: values ? 'Ⅳ (±)' : 'Ⅳ',
              fontSize: 20,
              width: 100,
              align: 'center',
              fill: 'rgba(0,0,0,0.8)',
              x: stageSize.width / 2 - 50,
              y: stageSize.height - padding + 10,
            }"
          />
          <!-- vectors -->
          <!-- v-if values -->
          <v-text
            :listening="false"
            v-if="values"
            :config="{
              text: vectors[0] === '' ? '–' : vectors[0],
              fontSize: 15,
              fill: vectors[0] === '' ? 'red' : '#0c93a4',
              width: 100,
              align: 'center',
              x: stageSize.width / 2 - 50,
              y: padding - 50,
            }"
          />
          <!--  -->
          <v-text
            :listening="false"
            v-if="values"
            :config="{
              text: vectors[1] === '' ? '–' : vectors[1],
              fontSize: 15,
              fill: vectors[1] === '' ? 'red' : '#0c93a4',
              width: 60,
              align: 'center',
              x: padding - 60,
              y: stageSize.height / 2 + 15,
            }"
          />
          <v-text
            :listening="false"
            v-if="values"
            :config="{
              text: vectors[2] === '' ? '–' : vectors[2],
              fontSize: 15,
              width: 60,
              align: 'center',
              fill: vectors[2] === '' ? 'red' : '#0c93a4',
              x: stageSize.width / 2 + padding + 10,
              y: stageSize.height / 2 + 15,
            }"
          />
          <v-text
            :listening="false"
            v-if="values"
            :config="{
              text: vectors[3] === '' ? '–' : vectors[3],
              fontSize: 15,
              width: 100,
              align: 'center',
              fill: vectors[3] === '' ? 'red' : '#0c93a4',
              x: stageSize.width / 2 - 50,
              y: stageSize.height - padding + 35,
            }"
          />
          <!-- v-if not values -->
          <v-text
            v-if="!values"
            ref="0"
            :config="{
              text: $t('test-szondi.CircleOneMeansControl'),
              fontSize: 15,
              fill: '#0c93a4',
              width: 150,
              align: 'center',
              x: stageSize.width / 2 - 75,
              y: padding - 50,
            }"
            @mousemove="handleMouseMove"
            @mouseout="handleMouseOut"
            @tap="handleTap"
          />
          <v-text
            v-if="!values"
            ref="1"
            :config="{
              text: $t('test-szondi.CircleTwoMeansControl'),
              fontSize: 15,
              width: 70,
              align: 'center',
              fill: '#0c93a4',
              x: padding - 75 + 100,
              y: stageSize.height / 2 + 15,
            }"
            @mousemove="handleMouseMove"
            @mouseout="handleMouseOut"
            @tap="handleTap"
          />
          <v-text
            v-if="!values"
            ref="2"
            :config="{
              text: $t('test-szondi.CircleThreeMeansControl'),
              fontSize: 15,
              fill: '#0c93a4',
              width: 70,
              align: 'center',
              x: stageSize.width - padding - 5 - 100,
              y: stageSize.height / 2 + 15,
            }"
            @mousemove="handleMouseMove"
            @mouseout="handleMouseOut"
            @tap="handleTap"
          />
          <v-text
            v-if="!values"
            ref="3"
            :config="{
              text: $t('test-szondi.CircleFourMeansControl'),
              fontSize: 15,
              fill: '#0c93a4',
              width: 150,
              align: 'center',
              x: stageSize.width / 2 - 75,
              y: stageSize.height - padding + 35,
            }"
            @mousemove="handleMouseMove"
            @mouseout="handleMouseOut"
            @tap="handleTap"
          />
          <!-- !!!!!! -->
          <v-text
            :listening="false"
            id="blur"
            v-if="!values"
            ref="0"
            :config="{
              text: data[0].value,
              fontSize: 12,
              fill: 'black',
              width: 200,
              padding: 10,
              height: 130,
              verticalAlign: 'bottom',
              align: 'justify',
              x: stageSize.width / 2 - 100,
              y: padding - 200,
            }"
          />
          <v-text
            id="blur"
            :listening="false"
            v-if="!values"
            ref="1"
            :config="{
              text: data[1].value,
              fontSize: 12,
              width: 200,
              padding: 10,
              height: 130,
              align: 'justify',
              fill: 'black',
              x: padding - 200 + 10,
              y: stageSize.height / 2,
            }"
          />
          <v-text
            id="blur"
            :listening="false"
            v-if="!values"
            ref="2"
            :config="{
              text: data[2].value,
              fontSize: 12,
              fill: 'black',
              width: 200,
              padding: 10,
              height: 130,
              align: 'justify',
              x: stageSize.width - padding - 15,
              y: stageSize.height / 2,
            }"
          />
          <v-text
            id="blur"
            :listening="false"
            v-if="!values"
            ref="3"
            :config="{
              text: data[3].value,
              fontSize: 12,
              fill: 'black',
              width: 200,
              padding: 10,
              verticalAlign: 'top',
              align: 'justify',
              x: stageSize.width / 2 - 100,
              y: stageSize.height - padding + 90,
            }"
          />

          <!--  -->
          <v-shape
            :listening="false"
            :config="{
              sceneFunc: function (context, shape) {
                let dimention =
                  stageSize.width < stageSize.height
                    ? stageSize.width
                    : stageSize.height;
                dimention -= padding * 2;
                const radius = dimention / 2;
                context.beginPath();
                context.moveTo(dimention / 2 + padding + 100, padding);
                context.lineTo(
                  dimention / 2 + padding + 100,
                  dimention + padding
                );
                context.fillStrokeShape(shape);
              },
              fill: 'transparent',
              stroke: direction ? '#0c93a4' : 'black',
              strokeWidth: direction ? 3 : 1,
            }"
          />
          <v-shape
            :listening="false"
            :config="{
              sceneFunc: function (context, shape) {
                let dimention =
                  stageSize.width < stageSize.height
                    ? stageSize.width
                    : stageSize.height;
                dimention -= padding * 2;
                const radius = dimention / 2;
                context.beginPath();
                context.moveTo(padding + 100, dimention / 2 + padding);
                context.lineTo(
                  dimention + padding + 100,
                  dimention / 2 + padding
                );
                context.fillStrokeShape(shape);
              },
              fill: 'transparent',
              stroke: direction ? '#0c93a4' : 'black',
              strokeWidth: direction ? 3 : 1,
            }"
          />
          <v-shape
            :listening="false"
            :config="{
              sceneFunc: function (context, shape) {
                let dimention =
                  stageSize.width < stageSize.height
                    ? stageSize.width
                    : stageSize.height;
                dimention -= padding * 2;
                const radius = dimention / 2;
                context.beginPath();
                context.moveTo(padding + 100, dimention / 2 + padding);
                context.arcTo(
                  padding + 100,
                  padding,
                  dimention / 2 + padding + 100,
                  padding,
                  radius
                );
                context.fillStrokeShape(shape);
              },
              fill: 'transparent',
              stroke: direction ? '#0c93a4' : 'black',
              strokeWidth: direction ? 3 : 1,
            }"
          />
          <v-shape
            :listening="false"
            :config="{
              sceneFunc: function (context, shape) {
                let dimention =
                  stageSize.width < stageSize.height
                    ? stageSize.width
                    : stageSize.height;
                dimention -= padding * 2;
                const radius = dimention / 2;
                context.beginPath();
                context.moveTo(dimention / 2 + padding + 100, padding);
                context.arcTo(
                  dimention + padding + 100,
                  padding,
                  dimention + padding + 100,
                  dimention / 2 + padding,
                  radius
                );
                context.fillStrokeShape(shape);
              },
              fill: 'transparent',
              stroke: 'black',
              strokeWidth: 1,
            }"
          />
          <v-shape
            :listening="false"
            :config="{
              sceneFunc: function (context, shape) {
                let dimention =
                  stageSize.width < stageSize.height
                    ? stageSize.width
                    : stageSize.height;
                dimention -= padding * 2;
                const radius = dimention / 2;
                context.beginPath();
                context.moveTo(
                  dimention / 2 + padding + 100,
                  dimention + padding
                );
                context.arcTo(
                  dimention + padding + 100,
                  dimention + padding,
                  dimention + padding + 100,
                  dimention / 2 + padding,
                  radius
                );
                context.fillStrokeShape(shape);
              },
              fill: 'transparent',
              stroke: 'black',
              strokeWidth: 1,
            }"
          />
          <v-shape
            :listening="false"
            :config="{
              sceneFunc: function (context, shape) {
                let dimention =
                  stageSize.width < stageSize.height
                    ? stageSize.width
                    : stageSize.height;
                dimention -= padding * 2;
                const radius = dimention / 2;
                context.beginPath();
                context.moveTo(
                  dimention / 2 + padding + 100,
                  dimention + padding
                );
                context.arcTo(
                  padding + 100,
                  dimention + padding,
                  padding + 100,
                  dimention / 2 + padding,
                  radius
                );

                // special Konva.js method
                context.fillStrokeShape(shape);
              },
              fill: 'transparent',
              stroke: 'black',
              strokeWidth: 1,
            }"
          />
          <!--  -->
          <v-arrow
            :listening="false"
            :config="{
              x: stageSize.width / 2,
              y: stageSize.height - padding,
              radius: 2,
              fill: 'black',
              stroke: 'black',
              strokeWidth: 0.5,
            }"
          />
          <v-arrow
            :listening="false"
            :config="{
              x: stageSize.width / 2,
              y: padding,
              radius: 2,
              fill: 'black',
              stroke: 'black',
              strokeWidth: 0.5,
              points: [0, 0, -1, 0],
            }"
          />
          <v-arrow
            :listening="false"
            :config="{
              x: padding + 100,
              y: stageSize.height / 2,
              radius: 2,
              fill: 'black',
              stroke: 'black',
              strokeWidth: 0.5,
              points: [0, 0, 0, 1],
            }"
          />
          <v-arrow
            :listening="false"
            :config="{
              x: stageSize.width - padding - 100,
              y: stageSize.height / 2,
              radius: 2,
              fill: 'black',
              stroke: 'black',
              strokeWidth: 0.5,
              points: [0, 0, 0, -1],
            }"
          />
          <!--  -->
          <v-arrow
            :listening="false"
            v-if="direction"
            :config="{
              x: stageSize.width / 2 - (padding / 4) * 2,
              y: stageSize.height / 2,
              radius: 1,
              fill: '#0c93a4',
              stroke: '#0c93a4',
              strokeWidth: 0.5,
            }"
          />
          <v-arrow
            :listening="false"
            v-if="direction"
            :config="{
              x: stageSize.width / 2 + (padding / 4) * 2,
              y: stageSize.height / 2,
              radius: 1,
              fill: '#0c93a4',
              stroke: '#0c93a4',
              strokeWidth: 0.5,
            }"
          />

          <v-arrow
            :listening="false"
            v-if="direction"
            :config="{
              x:
                (stageSize.width / 2 - padding) * Math.cos(3.9) +
                stageSize.width / 2,
              y:
                (stageSize.width / 2 - padding) * Math.sin(3.9) +
                stageSize.height / 2,
              radius: 2,
              fill: '#0c93a4',
              stroke: '#0c93a4',
              strokeWidth: 0.5,
              points: [0, 0, -0.5, 0.5],
            }"
          />
          <v-arrow
            :listening="false"
            v-if="direction"
            :config="{
              x: stageSize.width / 2,
              y: stageSize.height / 2 + (padding / 4) * 2,
              radius: 2,
              fill: '#0c93a4',
              stroke: '#0c93a4',
              strokeWidth: 0.5,
              points: [0, 0, 0, -1],
            }"
          />
          <v-arrow
            :listening="false"
            v-if="direction"
            :config="{
              x: stageSize.width / 2,
              y: stageSize.height / 2 - (padding / 4) * 2,
              radius: 2,
              fill: '#0c93a4',
              stroke: '#0c93a4',
              strokeWidth: 0.5,
              points: [0, 0, 0, -1],
            }"
          />
          <!--  -->
          <v-circle
            :listening="false"
            :config="{
              x: stageSize.width / 2,
              y: stageSize.height / 2,
              radius: 5,
              fill: 'black',
            }"
          />
          <!--  -->
        </v-layer>
      </v-stage>
    </a-popover>
  </section>
</template>

<script>
export default {
  props: {
    isAvailable: {
      default: false,
      type: Boolean,
    },
    padding: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    id: {
      type: Number,
      default: 0,
    },
    values: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    vectors: {
      type: Array,
      default: () => ["h, s", "e, hy", "k, p, d", "m"],
    },
    data: {
      type: Array,
      default: () => [
        { value: "Безсилие", image: "" },
        { value: "Требование того, что у него не должно быть", image: "" },
        {
          value:
            "Соединение 2ух семей (тайной и явной) - скрывает, что ходит в секту",
          image: "",
        },
        { value: "Гипертонический заклинатель будущего", image: "" },
      ],
    },
  },
  data() {
    return {
      currentTarget: null,
      popoverUrl: null,
      popoverVisible: false,
      currentTitle: "",

      tempSolution: [],
      tempIndex: 0,
      placements: ["top", "left", "right", "bottom"],
      offsets: [
        [0, 110],
        [200, 0],
        [-200, 0],
        [0, -140],
      ],
    };
  },
  computed: {
    stageSize() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
  mounted() {
    this.tempSolution = [
      this.$t("test-szondi.CircleOneMeansControl"),
      this.$t("test-szondi.CircleTwoMeansControl"),
      this.$t("test-szondi.CircleThreeMeansControl"),
      this.$t("test-szondi.CircleFourMeansControl"),
    ];
    this.cacheCall();
  },
  updated() {
    // recache
    this.cacheCall();
  },
  methods: {
    cacheCall() {
      const stage = this.$refs.stage.getStage();
      for (let i = 0; i < stage.children.length; i++) {
        for (let j = 0; j < stage.children[i].children.length; j++) {
          if (
            stage.children[i].children[j].VueComponent.$vnode.data.attrs.id ===
            "blur"
          ) {
            let node = stage.children[i].children[j];
            node.cache();
            if (!this.isAvailable) {
              node.filters([Konva.Filters.Pixelate]);
              node.pixelSize(6);
            } else {
              node.pixelSize(1);
            }
          }
        }
      }
    },
    handleTap(event) {
      if (this.popoverVisible) {
        this.handleMouseOut(event);
      } else {
        this.handleMouseMove(event);
      }
    },
    handleMouseOut() {
      this.currentTarget = null;
      this.popoverVisible = false;
    },
    handleMouseMove(event) {
      this.popoverVisible = true;
      // console.log(this.currentTarget, event.currentTarget.attrs.text)
      if (event && event.currentTarget && event.currentTarget.attrs.text) {
        if (this.currentTarget !== event.currentTarget.attrs.text) {
          const index = this.tempSolution.indexOf(
            event.currentTarget.attrs.text
          );
          this.currentTarget = event.currentTarget.attrs.text;
          if (index >= 0) {
            this.popoverUrl = this.data[index].image;
            // console.log(this.popoverUrl)
            this.tempIndex = index;
          }
          this.$store.commit("ui/setCirclePopoverIndex", this.id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//
// .invisiblepopover {
// position: absolute;
// left: 50%;
// top: 50%;

// transform: translate(-50%, -50%);
// }
</style>
