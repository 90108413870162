<template>
  <div v-if="resultedData" style="text-align: center">
    <h2>{{ $t("test-szondi.automationMethod") }}</h2>
    <div
      style="
        display: flex;
        flex-flow: column;
        max-width: 900px;
        overflow-x: auto;
        border-left: 4px solid rgb(59, 148, 165) !important;
        background-color: ghostwhite;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      "
    >
      <div style="display: flex; flex-flow: wrap; justify-content: center">
        <MalcevCircle
          :isAvailable="isAvailable"
          :title="$t('test-szondi.frontLabel')"
          :vectors="vectorsCircle(resultedData.frontResult)"
        />
        <MalcevCircle
          :isAvailable="isAvailable"
          :title="$t('test-szondi.backLabel')"
          :vectors="vectorsCircle(resultedData.backResult)"
        />
      </div>
      <div
        class="tabsResults"
        style="
          text-align: left;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          padding-left: 10px;
        "
      >
        <BottomText
          :isAvailable="isAvailable"
          :textOne="$t('test-szondi.CircleOneMeans')"
          :textTwo="$t('test-szondi.CircleFourMeans')"
          :textThree="$t('test-szondi.CircleThreeMeans')"
          :textFour="$t('test-szondi.CircleTwoMeans')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MalcevCircle from "./MalcevCircle.vue";
import BottomText from "./BottomText.vue";

export default {
  components: {
    MalcevCircle,
    BottomText,
  },
  props: {
    isAvailable: {
      default: false,
      type: Boolean,
    },
    resultedData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      //   vectorsSecondCircleData: null
    };
  },
  mounted() {
    // console.log("mounted", this.isAvailable);
    // this.vectorsSecondCircleData = {
    //   front: this.vectorsSecondCircle(this.resultedData.frontResult),
    //   back: this.vectorsSecondCircle(this.resultedData.backResult)
    // }
  },
  methods: {
    vectorsCircle(result) {
      const vPlus = [];
      const vPlusMinus = [];
      const vZero = [];
      const vMinus = [];

      const vecConst = ["h", "s", "e", "hy", "k", "p", "d", "m"];
      for (let i = 0; i < result.length; i++) {
        switch (result[i].split("!").join("")) {
          case "±":
            vPlusMinus.push(vecConst[i]);
            break;
          case "0":
            vZero.push(vecConst[i]);
            break;
          case "–":
            vMinus.push(vecConst[i]);
            break;
          case "+":
            vPlus.push(vecConst[i]);
            break;
        }
      }
      return [
        vPlus.join(", "),
        vMinus.join(", "),
        vZero.join(", "),
        vPlusMinus.join(", "),
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.resultCaption,
.resultNormal {
  text-align: left;
  max-width: 350px;
}
.resultCaption {
  font-weight: bold;
}
.tabsResults {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 700px; */
  margin-top: 30px;

  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;
}

.tabsResults {
  /* width: 100vw; */
  max-width: 1000px;
  /* width: fit-content; */
}

.sondi-results
  > div.tabsResults.ant-tabs.ant-tabs-top.ant-tabs-line
  > div.ant-tabs-bar.ant-tabs-top-bar {
  text-align: center;
}

.resultsTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}
</style>
