<template>
  <a-tabs v-if="tabResultsNewData" class="tabsResultsNew">
    <a-tab-pane
      v-for="(item, index) in tabResultsNewData"
      :key="item.id"
      :tab="item.tabName"
      style="text-align: left"
    >
      <h2 class="resultsTitle">
        {{ item.title }}
      </h2>
      <div
        style="
          display: flex;
          overflow-x: auto;
          max-width: 900px;
          margin-left: auto;
          margin-right: auto;
          border-left: 4px solid rgb(59, 148, 165) !important;
          background-color: ghostwhite;
        "
      >
        <VerticalAxis :is-woman="resultedData.isWoman" />
        <VerticalAxisData
          :isAvailable="isAvailable"
          :id="0 + index * 10"
          :title="$t('test-szondi.frontLabel')"
          :vectors="item.vectorsAxisFront"
          :data="item.strings"
        />
        <VerticalAxisData
          :isAvailable="isAvailable"
          :id="1 + index * 10"
          :title="$t('test-szondi.backLabel')"
          :vectors="item.vectorsAxisBack"
          :data="item.stringsBack"
        />
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import VerticalAxis from "./VerticalAxis.vue";
import VerticalAxisData from "./VerticalAxisData.vue";
export default {
  components: {
    VerticalAxis,
    VerticalAxisData,
  },
  props: {
    isAvailable: {
      default: false,
      type: Boolean,
    },
    resultedData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      idsCounter: 0,
      tabResultsNewData: null,
    };
  },
  computed: {
    tabResultsNew() {
      const resultTabs = [];
      // check user access to test results
      // end
      // default

      const sondiJsonName = this.$t("test-szondi.TestResultsSondiNew");
      // console.log(sondiJsonName)
      if (!sondiJsonName.trim()) {
        // is empty or whitespace
        return null;
      }

      const jsonObj = require(`../../../assets/interpretations/${sondiJsonName}`);

      const frR = this.resultedData.frontResult;
      const bR = this.resultedData.backResult;
      let idGen = 0;

      const vectorsArr = ["hs", "ehy", "kp", "dm"];

      for (let i = 0; i < jsonObj.titles.length; i++) {
        const TempResult = {
          title: jsonObj.titles[i],
          id: "tab" + i.toString(),
          tabName: jsonObj.names[i],
          strings: [],
          titleBack: jsonObj.titles[i],
          stringsBack: [],
        };
        // ПП--------------------------
        for (let iter = 0; iter < vectorsArr.length; iter++) {
          this.processResultDataNew(
            TempResult,
            jsonObj,
            vectorsArr[iter],
            idGen++,
            frR[iter * 2],
            frR[iter * 2 + 1],
            i,
            true
          );
        }

        // ЗП--------------------------
        for (let iter = 0; iter < vectorsArr.length; iter++) {
          this.processResultDataNew(
            TempResult,
            jsonObj,
            vectorsArr[iter],
            idGen++,
            bR[iter * 2],
            bR[iter * 2 + 1],
            i,
            false
          );
        }

        TempResult.vectorsAxisFront = this.vectorsAxis(frR);
        TempResult.vectorsAxisBack = this.vectorsAxis(bR);

        resultTabs.push(TempResult);
      }
      // console.log(resultTabs)
      return resultTabs;
    },
  },
  mounted() {
    this.tabResultsNewData = this.tabResultsNew;
  },
  methods: {
    vectorsAxis(result) {
      const vecConst = ["h", "s", "e", "hy", "k", "p", "d", "m"];
      return [
        vecConst[0] + result[0] + " " + vecConst[1] + result[1],
        vecConst[2] + result[2] + " " + vecConst[3] + result[3],
        vecConst[4] + result[4] + " " + vecConst[5] + result[5],
        vecConst[6] + result[6] + " " + vecConst[7] + result[7],
      ];
    },
    processResultDataNew(
      TempResult,
      jsonObj,
      vector,
      idGen,
      vA,
      vB,
      i,
      isFront
    ) {
      this.idsCounter += 1;
      const tempResVar = isFront ? TempResult.strings : TempResult.stringsBack;

      const tempStr = (vA + vB).split("!").join("");
      this.idsCounter += 1;
      if (jsonObj[vector][tempStr]) {
        tempResVar.push({
          value: jsonObj[vector][tempStr][i].value,
          image: this.$strapi.storageUrl + jsonObj[vector][tempStr][i].image,
          id: this.idsCounter.toString(),
        });
        // eslint-disable-next-line no-console
      } else if (this.isDev()) {
        alert(tempStr);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resultCaption,
.resultNormal {
  text-align: left;
  max-width: 350px;
}
.resultCaption {
  font-weight: bold;
}
.tabsResultsNew {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 700px; */
  margin-top: 30px;

  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;
}

.tabsResultsNew {
  /* width: 100vw; */
  max-width: 1000px;
  /* width: fit-content; */
}

.sondi-results
  > div.tabsResults.ant-tabs.ant-tabs-top.ant-tabs-line
  > div.ant-tabs-bar.ant-tabs-top-bar {
  text-align: center;
}

.resultsTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}
</style>
