<template>
  <a-tabs v-if="tabResultsData" class="tabsResults">
    <a-tab-pane
      v-for="item in tabResultsData"
      :key="item.id"
      :tab="item.tabName"
      style="text-align: left"
    >
      <h2 class="resultsTitle">
        {{ item.title }}
      </h2>
      <div
        style="
          display: flex;
          flex-flow: column;
          overflow-x: auto;
          padding-left: 20px;
          border-left: 4px solid rgb(59, 148, 165) !important;
          background-color: ghostwhite;
        "
      >
        <div v-for="str in item.strings" :key="str.id">
          <span :class="str.class">{{ str.val }}</span>
        </div>
      </div>
      <h2 class="resultsTitle">
        {{ item.titleBack }}
      </h2>
      <div
        style="
          display: flex;
          flex-flow: column;
          overflow-x: auto;
          padding-left: 20px;
          border-left: 4px solid rgb(59, 148, 165) !important;
          background-color: ghostwhite;
        "
      >
        <div v-for="strB in item.stringsBack" :key="strB.id">
          <span :class="strB.class">{{ strB.val }}</span>
        </div>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
export default {
  props: {
    resultedData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      idsCounter: 0,
      tabResultsData: null,
    };
  },
  computed: {
    tabResults() {
      const resultTabs = [];
      // check user access to test results
      // end
      // default

      const sondiJsonName = this.$t("test-szondi.TestResultsSondi");
      // console.log(sondiJsonName);
      if (!sondiJsonName.trim()) {
        // is empty or whitespace
        return null;
      }

      // console.log(sondiJsonName)
      const jsonObj = require(`../../../assets/interpretations/${sondiJsonName}`);
      // console.log(jsonObj)
      // const jsonObj = JSON.parse(sondiJson)
      const frR = this.resultedData.frontResult;
      const bR = this.resultedData.backResult;
      let idGen = 0;

      const vectorsLabelsArr = [
        this.$t("test-szondi.resultVectorS"),
        this.$t("test-szondi.resultVectorP"),
        this.$t("test-szondi.resultVectorSch"),
        this.$t("test-szondi.resultVectorC"),
      ];

      const vectorsArr = ["hs", "ehy", "kp", "dm"];

      for (let i = 0; i < jsonObj.titles.length; i++) {
        const TempResult = {
          title: jsonObj.titles[i] + " " + this.$t("test-szondi.frontLabel"),
          id: "tab" + i.toString(),
          tabName: jsonObj.names[i],
          strings: [],
          titleBack: jsonObj.titles[i] + " " + this.$t("test-szondi.backLabel"),
          stringsBack: [],
        };

        // ПП--------------------------
        for (let iter = 0; iter < vectorsArr.length; iter++) {
          this.processResultData(
            TempResult,
            jsonObj,
            vectorsLabelsArr[iter],
            vectorsArr[iter],
            idGen++,
            frR[iter * 2],
            frR[iter * 2 + 1],
            i,
            true
          );
        }

        // ЗП--------------------------
        for (let iter = 0; iter < vectorsArr.length; iter++) {
          this.processResultData(
            TempResult,
            jsonObj,
            vectorsLabelsArr[iter],
            vectorsArr[iter],
            idGen++,
            bR[iter * 2],
            bR[iter * 2 + 1],
            i,
            false
          );
        }

        resultTabs.push(TempResult);
      }
      // console.log(resultTabs)
      return resultTabs;
    },
  },
  mounted() {
    this.tabResultsData = this.tabResults;
    // console.log(this.tabResultsData);
  },
  methods: {
    processResultData(
      TempResult,
      jsonObj,
      caption,
      vector,
      idGen,
      vA,
      vB,
      i,
      isFront
    ) {
      this.idsCounter += 1;
      const tempResVar = isFront ? TempResult.strings : TempResult.stringsBack;
      tempResVar.push({
        val: caption,
        id: this.idsCounter.toString(),
        class: "resultCaption",
      });
      const tempStr = (vA + vB).split("!").join("");
      this.idsCounter += 1;
      if (jsonObj[vector][tempStr]) {
        tempResVar.push({
          val: jsonObj[vector][tempStr][i],
          id: this.idsCounter.toString(),
          class: "resultNormal",
        });
        // eslint-disable-next-line no-console
      } else if (this.isDev()) {
        alert(tempStr);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tabsResults {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 700px; */
  margin-top: 30px;

  margin-bottom: 30px;
  text-align: center;
  max-width: 900px;
}
.resultsTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}

.resultCaption,
.resultNormal {
  text-align: left;
  max-width: 350px;
}
.resultCaption {
  font-weight: bold;
}
</style>
