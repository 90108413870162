var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-popover',{attrs:{"title":_vm.currentTarget,"visible":_vm.popoverVisible && _vm.$store.state.ui.sondiPopoverIndex === _vm.id,"align":{ offset: _vm.offsets[_vm.tempIndex] },"placement":"left","auto-adjust-overflow":false,"arrow-point-at-center":true}},[_c('template',{slot:"content"},[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.popoverUrl}})]),_c('v-stage',{ref:"stage",staticStyle:{"margin":"10px"},attrs:{"config":_vm.stageSize}},[_c('v-layer',[_c('v-line',{attrs:{"listening":false,"config":{
          points: [10, _vm.padding, 10, _vm.stageSize.height - _vm.padding],
          stroke: 'black',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }}}),_c('v-circle',{attrs:{"listening":false,"config":{
          x: 10,
          y: _vm.padding,
          radius: 5,
          fill: 'black',
        }}}),_c('v-circle',{attrs:{"listening":false,"config":{
          x: 10,
          y: _vm.padding + (_vm.stageSize.height - _vm.padding * 2) / 3,
          radius: 5,
          fill: 'black',
        }}}),_c('v-circle',{attrs:{"listening":false,"config":{
          x: 10,
          y:
            _vm.stageSize.height - (_vm.stageSize.height - _vm.padding * 2) / 3 - _vm.padding,
          radius: 5,
          fill: 'black',
        }}}),_c('v-circle',{attrs:{"listening":false,"config":{
          x: 10,
          y: _vm.stageSize.height - _vm.padding,
          radius: 5,
          fill: 'black',
        }}}),_c('v-text',{attrs:{"listening":false,"config":{
          text: _vm.title,
          fontSize: 15,
          fill: 'gray',
          x: 10,
          y: 0,
        }}}),_c('v-text',{ref:"0",attrs:{"config":{
          text: _vm.vectors[3],
          fontSize: 15,
          fill: '#0c93a4',
          width: 240,
          x: 10 + 20,
          y: _vm.padding - 27,
        }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}),_c('v-text',{ref:"1",attrs:{"config":{
          text: _vm.vectors[2],
          fontSize: 15,
          fill: '#0c93a4',
          width: 240,
          x: 10 + 20,
          y: _vm.padding + (_vm.stageSize.height - _vm.padding * 2) / 3 - 27,
        }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}),_c('v-text',{ref:"2",attrs:{"config":{
          text: _vm.vectors[1],
          fontSize: 15,
          fill: '#0c93a4',
          width: 240,
          x: 10 + 20,
          y:
            _vm.stageSize.height -
            (_vm.stageSize.height - _vm.padding * 2) / 3 -
            _vm.padding -
            27,
        }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}),_c('v-text',{ref:"3",attrs:{"config":{
          text: _vm.vectors[0],
          fontSize: 15,
          fill: '#0c93a4',
          width: 240,
          x: 10 + 20,
          y: _vm.stageSize.height - _vm.padding - 27,
        }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}),_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
          text: _vm.data[3].value,
          fontSize: 12,
          fill: 'rgba(0,0,0,0.8)',
          width: 240,
          x: 10 + 20,
          y: _vm.padding - 7,
        }}}),_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
          text: _vm.data[2].value,
          fontSize: 12,
          fill: 'rgba(0,0,0,0.8)',
          width: 240,
          x: 10 + 20,
          y: _vm.padding + (_vm.stageSize.height - _vm.padding * 2) / 3 - 7,
        }}}),_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
          text: _vm.data[1].value,
          fontSize: 12,
          fill: 'rgba(0,0,0,0.8)',
          width: 240,
          x: 10 + 20,
          y:
            _vm.stageSize.height -
            (_vm.stageSize.height - _vm.padding * 2) / 3 -
            _vm.padding -
            7,
        }}}),_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
          text: _vm.data[0].value,
          fontSize: 12,
          fill: 'rgba(0,0,0,0.8)',
          width: 240,
          x: 10 + 20,
          y: _vm.stageSize.height - _vm.padding - 7,
        }}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }