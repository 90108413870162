<template>
  <v-stage ref="stage" :config="stageSize" style="margin: 0px">
    <v-layer>
      <v-text
        :listening="false"
        id="blur"
        :config="{
          text: 'Ⅰ (+) - ' + textOne,
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',

          align: 'left',
          x: 0,
          y: 0,
        }"
      />
      <v-text
        :listening="false"
        id="blur"
        :config="{
          text: 'Ⅱ (–) - ' + textTwo,
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',

          align: 'left',
          x: 0,
          y: 30 * 1,
        }"
      />
      <v-text
        :listening="false"
        id="blur"
        :config="{
          text: 'Ⅲ (0) - ' + textThree,
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',

          align: 'left',
          x: 0,
          y: 30 * 2,
        }"
      />
      <v-text
        :listening="false"
        id="blur"
        :config="{
          text: 'Ⅳ (±) - ' + textFour,
          fontSize: 15,
          fill: 'rgba(0,0,0,0.8)',

          align: 'left',
          x: 0,
          y: 30 * 3,
        }"
      />
    </v-layer>
  </v-stage>
</template>

<script>
export default {
  props: {
    isAvailable: {
      default: false,
      type: Boolean,
    },
    padding: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 120,
    },
    id: {
      type: Number,
      default: 0,
    },
    values: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: Boolean,
      default: true,
    },
    textOne: {
      type: String,
      default: "",
    },
    textTwo: {
      type: String,
      default: "",
    },
    textThree: {
      type: String,
      default: "",
    },
    textFour: {
      type: String,
      default: "",
    },
    vectors: {
      type: Array,
      default: () => ["h, s", "e, hy", "k, p, d", "m"],
    },
    data: {
      type: Array,
      default: () => [
        { value: "Безсилие", image: "" },
        { value: "Требование того, что у него не должно быть", image: "" },
        {
          value:
            "Соединение 2ух семей (тайной и явной) - скрывает, что ходит в секту",
          image: "",
        },
        { value: "Гипертонический заклинатель будущего", image: "" },
      ],
    },
  },
  data() {
    return {
      filters: [Konva.Filters.Pixelate],
      currentTarget: null,
      popoverUrl: null,
      popoverVisible: false,
      currentTitle: "",
    };
  },
  computed: {
    stageSize() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
  mounted() {
    this.cacheCall();
  },
  updated() {
    // recache
    this.cacheCall();
  },
  methods: {
    cacheCall() {
      const stage = this.$refs.stage.getStage();
      for (let i = 0; i < stage.children.length; i++) {
        for (let j = 0; j < stage.children[i].children.length; j++) {
          // console.log(
          //   this.isAvailable,
          //   stage.children[i].children[j].VueComponent.$vnode.data
          // );
          if (
            stage.children[i].children[j].VueComponent.$vnode.data.attrs.id ===
            "blur"
          ) {
            let node = stage.children[i].children[j];
            node.cache();
            node.filters([Konva.Filters.Pixelate]);
            if (!this.isAvailable) {
              node.pixelSize(6);
            } else {
              node.pixelSize(1);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
