var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stage',{ref:"stage",staticStyle:{"margin":"0px"},attrs:{"config":_vm.stageSize}},[_c('v-layer',[_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
        text: 'Ⅰ (+) - ' + _vm.textOne,
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',

        align: 'left',
        x: 0,
        y: 0,
      }}}),_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
        text: 'Ⅱ (–) - ' + _vm.textTwo,
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',

        align: 'left',
        x: 0,
        y: 30 * 1,
      }}}),_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
        text: 'Ⅲ (0) - ' + _vm.textThree,
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',

        align: 'left',
        x: 0,
        y: 30 * 2,
      }}}),_c('v-text',{attrs:{"listening":false,"id":"blur","config":{
        text: 'Ⅳ (±) - ' + _vm.textFour,
        fontSize: 15,
        fill: 'rgba(0,0,0,0.8)',

        align: 'left',
        x: 0,
        y: 30 * 3,
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }