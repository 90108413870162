<template>
  <a-popover
    :title="currentTarget"
    :visible="popoverVisible && $store.state.ui.sondiPopoverIndex === id"
    :align="{ offset: offsets[tempIndex] }"
    placement="left"
    :auto-adjust-overflow="false"
    :arrow-point-at-center="true"
  >
    <template slot="content">
      <img style="width: 200px" :src="popoverUrl" />
    </template>
    <v-stage ref="stage" :config="stageSize" style="margin: 10px">
      <v-layer>
        <v-line
          :listening="false"
          :config="{
            points: [10, padding, 10, stageSize.height - padding],
            stroke: 'black',
            strokeWidth: 1,
            lineCap: 'round',
            lineJoin: 'round',
          }"
        />
        <!--  -->

        <!-- <a-button type="primary">Hover me</a-button> -->

        <v-circle
          :listening="false"
          :config="{
            x: 10,
            y: padding,
            radius: 5,
            fill: 'black',
          }"
        />

        <v-circle
          :listening="false"
          :config="{
            x: 10,
            y: padding + (stageSize.height - padding * 2) / 3,
            radius: 5,
            fill: 'black',
          }"
        />
        <v-circle
          :listening="false"
          :config="{
            x: 10,
            y:
              stageSize.height - (stageSize.height - padding * 2) / 3 - padding,
            radius: 5,
            fill: 'black',
          }"
        />
        <v-circle
          :listening="false"
          :config="{
            x: 10,
            y: stageSize.height - padding,
            radius: 5,
            fill: 'black',
          }"
        />
        <!--  -->
        <v-text
          :listening="false"
          :config="{
            text: title,
            fontSize: 15,
            fill: 'gray',
            x: 10,
            y: 0,
          }"
        />
        <!-- vectors -->
        <v-text
          ref="0"
          :config="{
            text: vectors[3],
            fontSize: 15,
            fill: '#0c93a4',
            width: 240,
            x: 10 + 20,
            y: padding - 27,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          ref="1"
          :config="{
            text: vectors[2],
            fontSize: 15,
            fill: '#0c93a4',
            width: 240,
            x: 10 + 20,
            y: padding + (stageSize.height - padding * 2) / 3 - 27,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          ref="2"
          :config="{
            text: vectors[1],
            fontSize: 15,
            fill: '#0c93a4',
            width: 240,
            x: 10 + 20,
            y:
              stageSize.height -
              (stageSize.height - padding * 2) / 3 -
              padding -
              27,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <v-text
          ref="3"
          :config="{
            text: vectors[0],
            fontSize: 15,
            fill: '#0c93a4',
            width: 240,
            x: 10 + 20,
            y: stageSize.height - padding - 27,
          }"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @tap="handleTap"
        />
        <!-- data -->
        <v-text
          :listening="false"
          id="blur"
          :config="{
            text: data[3].value,
            fontSize: 12,
            fill: 'rgba(0,0,0,0.8)',
            width: 240,
            x: 10 + 20,
            y: padding - 7,
          }"
        />
        <v-text
          :listening="false"
          id="blur"
          :config="{
            text: data[2].value,
            fontSize: 12,
            fill: 'rgba(0,0,0,0.8)',
            width: 240,
            x: 10 + 20,
            y: padding + (stageSize.height - padding * 2) / 3 - 7,
          }"
        />
        <v-text
          :listening="false"
          id="blur"
          :config="{
            text: data[1].value,
            fontSize: 12,
            fill: 'rgba(0,0,0,0.8)',
            width: 240,
            x: 10 + 20,
            y:
              stageSize.height -
              (stageSize.height - padding * 2) / 3 -
              padding -
              7,
          }"
        />
        <v-text
          :listening="false"
          id="blur"
          :config="{
            text: data[0].value,
            fontSize: 12,
            fill: 'rgba(0,0,0,0.8)',
            width: 240,
            x: 10 + 20,
            y: stageSize.height - padding - 7,
          }"
        />
      </v-layer>
    </v-stage>
  </a-popover>
</template>

<script>
const width = 300; // window.innerWidth
const height = 900; // window.innerHeight

export default {
  props: {
    isAvailable: {
      default: false,
      type: Boolean,
    },
    id: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    vectors: {
      type: Array,
      default: () => ["d- m-", "k- p0", "e+ hy-", "h+ s+"],
    },
    data: {
      type: Array,
      default: () => [
        { value: "Безсилие", image: "" },
        { value: "Требование того, что у него не должно быть", image: "" },
        {
          value:
            "Соединение 2ух семей (тайной и явной) - скрывает, что ходит в секту",
          image: "",
        },
        { value: "Гипертонический заклинатель будущего", image: "" },
      ],
    },
  },
  data() {
    return {
      stageSize: {
        width,
        height,
      },
      padding: 100,
      currentTarget: null,
      popoverUrl: null,
      popoverVisible: false,

      tempIndex: 0,
      offsets: [4],
    };
  },
  mounted() {
    this.offsets = [
      [0, 160 + 70 + 120],
      [0, -5 + 120],
      [0, -170 + 60],
      [0, -335 - 10],
    ];
    this.cacheCall();
  },
  updated() {
    // recache
    this.cacheCall();
  },
  methods: {
    cacheCall() {
      const stage = this.$refs.stage.getStage();
      for (let i = 0; i < stage.children.length; i++) {
        for (let j = 0; j < stage.children[i].children.length; j++) {
          if (
            stage.children[i].children[j].VueComponent.$vnode.data.attrs.id ===
            "blur"
          ) {
            let node = stage.children[i].children[j];
            node.cache();
            node.filters([Konva.Filters.Pixelate]);

            if (!this.isAvailable) {
              node.pixelSize(6);
            } else {
              node.pixelSize(1);
            }
          }
        }
      }
    },
    handleTap(event) {
      if (this.popoverVisible) {
        this.handleMouseOut(event);
      } else {
        this.handleMouseMove(event);
      }
    },
    handleMouseOut() {
      this.currentTarget = null;
      this.popoverVisible = false;
      // console.log('handleMouseOut')
      // this.writeMessage('Mouseout triangle');
    },
    handleMouseMove(event) {
      this.popoverVisible = true;
      if (event && event.currentTarget && event.currentTarget._partialText) {
        if (this.currentTarget !== event.currentTarget._partialText) {
          const index = this.vectors.indexOf(event.currentTarget._partialText);
          this.currentTarget = event.currentTarget._partialText;
          if (index >= 0) {
            this.tempIndex = index;
            this.popoverUrl = this.data[index].image;
          }
          this.$store.commit("ui/setSondiPopoverIndex", this.id);
        }
      }
      // console.log(event.currentTarget._partialText)
      // const mousePos = this.$refs.stage.getStage().getPointerPosition()
      // const x = mousePos.x - 190
      // const y = mousePos.y - 40
      // this.writeMessage('x: ' + x + ', y: ' + y);
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
