<template>
  <div
    v-if="resultedData && vectorsSecondCircleData"
    style="text-align: center; overflow-x: scroll"
  >
    <h2>{{ $t("test-szondi.controlMethod") }}</h2>
    <div
      style="
        display: inline-block;
        overflow-x: auto;
        max-width: 900px;
        justify-content: center;
        border-left: 4px solid rgb(59, 148, 165) !important;
        background-color: ghostwhite;
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      "
    >
      <div
        style="
          display: flex;
          flex-flow: column;
          justify-content: center;
          min-width: fit-content;
        "
      >
        <div
          style="
            justify-content: center;
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <MalcevCircle
            :isAvailable="isAvailable"
            :id="1 + 1001"
            :title="$t('test-szondi.frontLabel')"
            :data="
              vectorsSecondCircleData
                ? vectorsSecondCircleData.front
                : undefined
            "
            :direction="false"
            :values="false"
            :width="700"
            :height="500"
            :padding="200"
          />
        </div>
        <div
          style="
            justify-content: center;
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <MalcevCircle
            :isAvailable="isAvailable"
            :id="1 + 1002"
            :title="$t('test-szondi.backLabel')"
            :data="
              vectorsSecondCircleData ? vectorsSecondCircleData.back : undefined
            "
            :direction="false"
            :values="false"
            :width="700"
            :height="500"
            :padding="200"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MalcevCircle from "./MalcevCircle.vue";

export default {
  components: {
    MalcevCircle,
  },
  props: {
    isAvailable: {
      default: false,
      type: Boolean,
    },
    resultedData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      vectorsSecondCircleData: null,
    };
  },
  mounted() {
    this.vectorsSecondCircleData = {
      front: this.vectorsSecondCircle(this.resultedData.frontResult),
      back: this.vectorsSecondCircle(this.resultedData.backResult),
    };
  },
  methods: {
    vectorsSecondCircle(result) {
      const sondiJsonName = this.$t("test-szondi.TestResultsSondiNew");
      // console.log(sondiJsonName)
      if (!sondiJsonName.trim()) {
        // is empty or whitespace
        return null;
      }

      // console.log(sondiJsonName)
      const jsonObj = require(`../../../assets/interpretations/${sondiJsonName}`);
      const vectorsArr = ["hs", "ehy", "kp", "dm"];
      //
      const i = 0; // "Тактика" !!!!
      let idGen = 0;
      //
      const TempResult = [];

      // -- filling data
      for (let iter = 0; iter < vectorsArr.length; iter++) {
        this.processResultDataCircle(
          TempResult,
          jsonObj,
          vectorsArr[iter],
          idGen++,
          result[iter * 2],
          result[iter * 2 + 1],
          i
        );
      }
      return TempResult;
    },
    processResultDataCircle(TempResult, jsonObj, vector, idGen, vA, vB, i) {
      this.idsCounter += 1;
      const tempStr = (vA + vB).split("!").join("");
      this.idsCounter += 1;
      if (jsonObj[vector][tempStr]) {
        TempResult.push({
          value: jsonObj[vector][tempStr][i].value,
          image: this.$strapi.storageUrl + jsonObj[vector][tempStr][i].image,
          id: this.idsCounter.toString(),
        });
        // eslint-disable-next-line no-console
      } else if (this.isDev()) {
        alert(tempStr);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resultCaption,
.resultNormal {
  text-align: left;
  max-width: 350px;
}
.resultCaption {
  font-weight: bold;
}
.tabsResults {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 700px; */
  margin-top: 30px;

  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;
}

.tabsResults {
  /* width: 100vw; */
  max-width: 1000px;
  /* width: fit-content; */
}

.sondi-results
  > div.tabsResults.ant-tabs.ant-tabs-top.ant-tabs-line
  > div.ant-tabs-bar.ant-tabs-top-bar {
  text-align: center;
}

.resultsTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}
</style>
