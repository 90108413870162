<template>
  <section v-if="isMounted">
    <div v-if="resultedData" id="sondi-results" class="sondi-results">
      <Graph ref="graph" :resulted-data="resultedData" />
      <Basic :resulted-data="resultedData" />
      <CircleSimple
        :isAvailable="isAvailable('AutomationMethod')"
        :resulted-data="resultedData"
      />
      <Vertical
        :isAvailable="isAvailable('TacticsFactors')"
        :resulted-data="resultedData"
      />
      <CircleDetailed
        :isAvailable="isAvailable('Management')"
        :resulted-data="resultedData"
      />
    </div>
  </section>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import Basic from "@/components/Interpretations/Basic";
import Graph from "@/components/Interpretations/Graph";
import Vertical from "@/components/Interpretations/Vertical";
import CircleSimple from "@/components/Interpretations/CircleSimple";
import CircleDetailed from "@/components/Interpretations/CircleDetailed";

export default {
  components: {
    Graph,
    Basic,
    Vertical,
    CircleSimple,
    CircleDetailed,
  },
  props: {
    result: {
      default: null,
      type: Object,
    },
    resultedData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      isMounted: false,
      idsCounter: 0,
      interpretationsAvailable: [],
    };
  },
  computed: {
    id() {
      return this.id;
    },
    gender() {
      return this.gender;
    },
    profile() {
      return this.profile;
    },
    ...mapGetters(["id", "profile", "gender", "isInterpretator", "isAdmin"]),
  },
  async mounted() {
    if (!this.$isServer) {
      // this.id = params
      if (!this.id || !this.$store.state.authCustom.auth) {
        await this.$store.dispatch("authCustom/authAction");
      }
      let disabled =
        !this.$store.state.authCustom.auth ||
        !this.result.user ||
        this.id !== this.result.user;
      if (this.isInterpretator || this.isAdmin) {
        disabled = false;
      }
      const data = {
        textId: "buttons.downloadGraph",
        visible: true,
        disabled,
      };
      const callback = this.downloadGraph;
      this.$store.commit("ui/setActionButton", {
        data,
        callback,
      });
    }
    this.interpretationsAvailable = await this.$store.dispatch(
      "authCustom/fetchInterpretations"
    );
    // console.log(this.interpretationsAvailable);
    this.isMounted = true;
    // console.log(interpretations)
    // console.log(this.$refs);
    // console.log(document.getElementById('chart1'));
  },
  methods: {
    isAvailable(interpretationName) {
      // return true;
      // console.log('interpretationName', interpretationName, this.interpretationsAvailable);
      if (!this.interpretationsAvailable) {
        return false;
      }
      for (let i = 0; i < this.interpretationsAvailable.length; i++) {
        if (
          this.interpretationsAvailable[i].SystemName === interpretationName
        ) {
          return true;
        }
      }
      return false;
    },
    ...mapMutations({ setActionButton: "ui/setActionButton" }),
    async downloadGraph() {
      if (!this.$isServer) {
        // this.$nextTick(() => {
        //   this.$loading.start();
        //   // setTimeout(() => this.$loading.finish(), 500)
        // });

        // console.log(this.$refs['graph'].$refs['chartOne'].$refs['chart'].getStage());
        // console.log(this.$refs['graph'].$refs['chartTwo'].$refs['chart'].getStage());
        // return;
        // const frontCanvas = document.getElementById('chart1')[0][0];
        // const backCanvas = document.getElementById('chart2')[0][0];

        // create image from dummy canvas
        const frontCanvasImg = this.$refs["graph"].$refs["chartOne"].$refs[
          "chart"
        ]
          .getStage()
          .toDataURL(); // frontCanvas.toDataURL('image/png', 1.0);
        const backCanvasImg = this.$refs["graph"].$refs["chartTwo"].$refs[
          "chart"
        ]
          .getStage()
          .toDataURL(); // backCanvas.toDataURL('image/png', 1.0);

        const today = new Date(this.result.dateend);
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        const todayStr =
          dd.toString() +
          "/" +
          mm.toString() +
          "/" +
          yyyy.toString() +
          " " +
          today.toLocaleTimeString().slice(0, -3);
        const caption1 = this.$t("test.testResultsDate") + " " + todayStr;

        const fileName =
          this.$t("test-szondi.testResultsPrefix") + todayStr + ".pdf";

        const pdfMake = require("pdfmake");

        const pdfFonts = require("pdfmake/build/vfs_fonts");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        let profile = this.profile;
        if (this.isInterpretator || this.isAdmin) {
          profile = await this.$store.dispatch(
            "users/fetchUser",
            this.result.user
          );
        }
        const caption2 = profile.firstname + " " + profile.secondname;

        const docDefinition = {
          info: {
            title: this.$t("meta.title.default"),
            author: "https://psyhotest.com",
            subject: this.$t("test-szondi.TestResultsSubjectProfTesting"),
            keywords: this.$t("meta.title.default"),
          },
          pageSize: "A5", // 1191 pixels x 842 pixels
          // 420 pixels x 595 pixels
          pageMargins: [10, 10, 10, 0],
          pageOrientation: "landscape",
          content: [
            {
              alignment: "left",
              text: caption1,
              width: 580,
              color: "gray",
              fontSize: 10,
            },
            {
              alignment: "left",
              text: caption2,
              width: 580,
              color: "black",
              fontSize: 12,
            },
            {
              alignment: "justify",
              columns: [
                {
                  image: "fr",
                  width: 250, // for 50 % image width
                  height: 333, // change the numbers accordingly
                  absolutePosition: {
                    // absolute positioning if required
                    x: 35,
                    y: 45,
                  },
                },
                {
                  image: "bk",
                  width: 250, // for 50 % image width
                  height: 333, // change the numbers accordingly
                  absolutePosition: {
                    // absolute positioning if required
                    x: 300,
                    y: 45,
                  },
                },
              ],
            },
            {
              alignment: "right",
              text: this.$t("test-szondi.TestResultsAdv"),
              link: "https://psyhotest.com/results/" + this.result.udid,
              width: 580,
              absolutePosition: {
                // absolute positioning if required
                x: 20,
                y: 400,
              },
              color: "gray",
              fontSize: 10,
            },
          ],
          images: {
            fr: frontCanvasImg,
            bk: backCanvasImg,
          },
          compress: false,
          styles: {
            header: {
              fontSize: 15,
              bold: true,
            },
            body: {},
            anotherStyle: {
              italics: true,
              alignment: "right",
            },
          },
          defaultStyle: {
            fontSize: 12,
            bold: false,
            // fillColor: '#bada55'
          },
        };

        //console.log(docDefinition);

        pdfMake.createPdf(docDefinition).download(fileName);

        // this.$nextTick(() => {
        //   // this.$loading.start()
        //   setTimeout(() => this.$loading.finish(), 500);
        // });
        return null;
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
.chart-grid {
  display: flex;
  text-align: center;
  place-content: normal;
  justify-items: center;
  overflow: hidden;
  justify-content: center;
  flex-flow: wrap;
  padding-left: 3%;
}

.sondi-results {
  /* margin-top: 70px; */
  padding-top: 0px;
  /* padding-top: 10px; */
}
</style>
