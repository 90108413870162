var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.data && _vm.data.length === 4) || (_vm.vectors && _vm.vectors.length === 4))?_c('section',[_c('a-popover',{attrs:{"title":_vm.currentTarget,"visible":_vm.popoverVisible && _vm.$store.state.ui.circlePopoverIndex === _vm.id,"placement":!_vm.$device.mobile ? _vm.placements[_vm.tempIndex] : 'top',"auto-adjust-overflow":false,"arrow-point-at-center":true,"align":{ offset: !_vm.$device.mobile ? _vm.offsets[_vm.tempIndex] : [0, 110] }}},[_c('template',{slot:"content"},[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.popoverUrl}})]),_c('v-stage',{ref:"stage",staticStyle:{"margin":"0px"},attrs:{"config":_vm.stageSize}},[_c('v-layer',[_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.title,
            fontSize: 12,
            fill: 'gray',
            x: _vm.stageSize.width / 2 - 100,
            y: _vm.stageSize.height / 2 - 100,
          }}}),_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.values ? 'Ⅰ (+)' : 'Ⅰ',
            fontSize: 20,
            fill: 'rgba(0,0,0,0.8)',
            width: 100,
            align: 'center',
            x: _vm.stageSize.width / 2 - 50,
            y: _vm.padding - 30,
          }}}),_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.values ? '(–) Ⅱ' : 'Ⅱ',
            fontSize: 20,
            fill: 'rgba(0,0,0,0.8)',
            x: _vm.values ? _vm.padding - 60 : _vm.padding - 25 + 100,
            y: _vm.stageSize.height / 2 - 10,
          }}}),_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.values ? 'Ⅲ (0)' : 'Ⅲ',
            fontSize: 20,
            fill: 'rgba(0,0,0,0.8)',
            x: _vm.stageSize.width - _vm.padding + 10 - 100,
            y: _vm.stageSize.height / 2 - 10,
          }}}),_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.values ? 'Ⅳ (±)' : 'Ⅳ',
            fontSize: 20,
            width: 100,
            align: 'center',
            fill: 'rgba(0,0,0,0.8)',
            x: _vm.stageSize.width / 2 - 50,
            y: _vm.stageSize.height - _vm.padding + 10,
          }}}),(_vm.values)?_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.vectors[0] === '' ? '–' : _vm.vectors[0],
            fontSize: 15,
            fill: _vm.vectors[0] === '' ? 'red' : '#0c93a4',
            width: 100,
            align: 'center',
            x: _vm.stageSize.width / 2 - 50,
            y: _vm.padding - 50,
          }}}):_vm._e(),(_vm.values)?_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.vectors[1] === '' ? '–' : _vm.vectors[1],
            fontSize: 15,
            fill: _vm.vectors[1] === '' ? 'red' : '#0c93a4',
            width: 60,
            align: 'center',
            x: _vm.padding - 60,
            y: _vm.stageSize.height / 2 + 15,
          }}}):_vm._e(),(_vm.values)?_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.vectors[2] === '' ? '–' : _vm.vectors[2],
            fontSize: 15,
            width: 60,
            align: 'center',
            fill: _vm.vectors[2] === '' ? 'red' : '#0c93a4',
            x: _vm.stageSize.width / 2 + _vm.padding + 10,
            y: _vm.stageSize.height / 2 + 15,
          }}}):_vm._e(),(_vm.values)?_c('v-text',{attrs:{"listening":false,"config":{
            text: _vm.vectors[3] === '' ? '–' : _vm.vectors[3],
            fontSize: 15,
            width: 100,
            align: 'center',
            fill: _vm.vectors[3] === '' ? 'red' : '#0c93a4',
            x: _vm.stageSize.width / 2 - 50,
            y: _vm.stageSize.height - _vm.padding + 35,
          }}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"0",attrs:{"config":{
            text: _vm.$t('test-szondi.CircleOneMeansControl'),
            fontSize: 15,
            fill: '#0c93a4',
            width: 150,
            align: 'center',
            x: _vm.stageSize.width / 2 - 75,
            y: _vm.padding - 50,
          }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"1",attrs:{"config":{
            text: _vm.$t('test-szondi.CircleTwoMeansControl'),
            fontSize: 15,
            width: 70,
            align: 'center',
            fill: '#0c93a4',
            x: _vm.padding - 75 + 100,
            y: _vm.stageSize.height / 2 + 15,
          }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"2",attrs:{"config":{
            text: _vm.$t('test-szondi.CircleThreeMeansControl'),
            fontSize: 15,
            fill: '#0c93a4',
            width: 70,
            align: 'center',
            x: _vm.stageSize.width - _vm.padding - 5 - 100,
            y: _vm.stageSize.height / 2 + 15,
          }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"3",attrs:{"config":{
            text: _vm.$t('test-szondi.CircleFourMeansControl'),
            fontSize: 15,
            fill: '#0c93a4',
            width: 150,
            align: 'center',
            x: _vm.stageSize.width / 2 - 75,
            y: _vm.stageSize.height - _vm.padding + 35,
          }},on:{"mousemove":_vm.handleMouseMove,"mouseout":_vm.handleMouseOut,"tap":_vm.handleTap}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"0",attrs:{"listening":false,"id":"blur","config":{
            text: _vm.data[0].value,
            fontSize: 12,
            fill: 'black',
            width: 200,
            padding: 10,
            height: 130,
            verticalAlign: 'bottom',
            align: 'justify',
            x: _vm.stageSize.width / 2 - 100,
            y: _vm.padding - 200,
          }}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"1",attrs:{"id":"blur","listening":false,"config":{
            text: _vm.data[1].value,
            fontSize: 12,
            width: 200,
            padding: 10,
            height: 130,
            align: 'justify',
            fill: 'black',
            x: _vm.padding - 200 + 10,
            y: _vm.stageSize.height / 2,
          }}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"2",attrs:{"id":"blur","listening":false,"config":{
            text: _vm.data[2].value,
            fontSize: 12,
            fill: 'black',
            width: 200,
            padding: 10,
            height: 130,
            align: 'justify',
            x: _vm.stageSize.width - _vm.padding - 15,
            y: _vm.stageSize.height / 2,
          }}}):_vm._e(),(!_vm.values)?_c('v-text',{ref:"3",attrs:{"id":"blur","listening":false,"config":{
            text: _vm.data[3].value,
            fontSize: 12,
            fill: 'black',
            width: 200,
            padding: 10,
            verticalAlign: 'top',
            align: 'justify',
            x: _vm.stageSize.width / 2 - 100,
            y: _vm.stageSize.height - _vm.padding + 90,
          }}}):_vm._e(),_c('v-shape',{attrs:{"listening":false,"config":{
            sceneFunc: function (context, shape) {
              let dimention =
                _vm.stageSize.width < _vm.stageSize.height
                  ? _vm.stageSize.width
                  : _vm.stageSize.height;
              dimention -= _vm.padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(dimention / 2 + _vm.padding + 100, _vm.padding);
              context.lineTo(
                dimention / 2 + _vm.padding + 100,
                dimention + _vm.padding
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: _vm.direction ? '#0c93a4' : 'black',
            strokeWidth: _vm.direction ? 3 : 1,
          }}}),_c('v-shape',{attrs:{"listening":false,"config":{
            sceneFunc: function (context, shape) {
              let dimention =
                _vm.stageSize.width < _vm.stageSize.height
                  ? _vm.stageSize.width
                  : _vm.stageSize.height;
              dimention -= _vm.padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(_vm.padding + 100, dimention / 2 + _vm.padding);
              context.lineTo(
                dimention + _vm.padding + 100,
                dimention / 2 + _vm.padding
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: _vm.direction ? '#0c93a4' : 'black',
            strokeWidth: _vm.direction ? 3 : 1,
          }}}),_c('v-shape',{attrs:{"listening":false,"config":{
            sceneFunc: function (context, shape) {
              let dimention =
                _vm.stageSize.width < _vm.stageSize.height
                  ? _vm.stageSize.width
                  : _vm.stageSize.height;
              dimention -= _vm.padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(_vm.padding + 100, dimention / 2 + _vm.padding);
              context.arcTo(
                _vm.padding + 100,
                _vm.padding,
                dimention / 2 + _vm.padding + 100,
                _vm.padding,
                radius
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: _vm.direction ? '#0c93a4' : 'black',
            strokeWidth: _vm.direction ? 3 : 1,
          }}}),_c('v-shape',{attrs:{"listening":false,"config":{
            sceneFunc: function (context, shape) {
              let dimention =
                _vm.stageSize.width < _vm.stageSize.height
                  ? _vm.stageSize.width
                  : _vm.stageSize.height;
              dimention -= _vm.padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(dimention / 2 + _vm.padding + 100, _vm.padding);
              context.arcTo(
                dimention + _vm.padding + 100,
                _vm.padding,
                dimention + _vm.padding + 100,
                dimention / 2 + _vm.padding,
                radius
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 1,
          }}}),_c('v-shape',{attrs:{"listening":false,"config":{
            sceneFunc: function (context, shape) {
              let dimention =
                _vm.stageSize.width < _vm.stageSize.height
                  ? _vm.stageSize.width
                  : _vm.stageSize.height;
              dimention -= _vm.padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(
                dimention / 2 + _vm.padding + 100,
                dimention + _vm.padding
              );
              context.arcTo(
                dimention + _vm.padding + 100,
                dimention + _vm.padding,
                dimention + _vm.padding + 100,
                dimention / 2 + _vm.padding,
                radius
              );
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 1,
          }}}),_c('v-shape',{attrs:{"listening":false,"config":{
            sceneFunc: function (context, shape) {
              let dimention =
                _vm.stageSize.width < _vm.stageSize.height
                  ? _vm.stageSize.width
                  : _vm.stageSize.height;
              dimention -= _vm.padding * 2;
              const radius = dimention / 2;
              context.beginPath();
              context.moveTo(
                dimention / 2 + _vm.padding + 100,
                dimention + _vm.padding
              );
              context.arcTo(
                _vm.padding + 100,
                dimention + _vm.padding,
                _vm.padding + 100,
                dimention / 2 + _vm.padding,
                radius
              );

              // special Konva.js method
              context.fillStrokeShape(shape);
            },
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 1,
          }}}),_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width / 2,
            y: _vm.stageSize.height - _vm.padding,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
          }}}),_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width / 2,
            y: _vm.padding,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
            points: [0, 0, -1, 0],
          }}}),_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.padding + 100,
            y: _vm.stageSize.height / 2,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
            points: [0, 0, 0, 1],
          }}}),_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width - _vm.padding - 100,
            y: _vm.stageSize.height / 2,
            radius: 2,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0.5,
            points: [0, 0, 0, -1],
          }}}),(_vm.direction)?_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width / 2 - (_vm.padding / 4) * 2,
            y: _vm.stageSize.height / 2,
            radius: 1,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
          }}}):_vm._e(),(_vm.direction)?_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width / 2 + (_vm.padding / 4) * 2,
            y: _vm.stageSize.height / 2,
            radius: 1,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
          }}}):_vm._e(),(_vm.direction)?_c('v-arrow',{attrs:{"listening":false,"config":{
            x:
              (_vm.stageSize.width / 2 - _vm.padding) * Math.cos(3.9) +
              _vm.stageSize.width / 2,
            y:
              (_vm.stageSize.width / 2 - _vm.padding) * Math.sin(3.9) +
              _vm.stageSize.height / 2,
            radius: 2,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
            points: [0, 0, -0.5, 0.5],
          }}}):_vm._e(),(_vm.direction)?_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width / 2,
            y: _vm.stageSize.height / 2 + (_vm.padding / 4) * 2,
            radius: 2,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
            points: [0, 0, 0, -1],
          }}}):_vm._e(),(_vm.direction)?_c('v-arrow',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width / 2,
            y: _vm.stageSize.height / 2 - (_vm.padding / 4) * 2,
            radius: 2,
            fill: '#0c93a4',
            stroke: '#0c93a4',
            strokeWidth: 0.5,
            points: [0, 0, 0, -1],
          }}}):_vm._e(),_c('v-circle',{attrs:{"listening":false,"config":{
            x: _vm.stageSize.width / 2,
            y: _vm.stageSize.height / 2,
            radius: 5,
            fill: 'black',
          }}})],1)],1)],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }